<script>
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config";
import { mapGetters, mapActions } from 'vuex';
import EditForm from "./edit-form"

export default {
 
  page: {
    title: "User Details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  
  components: {
    Layout,
    EditForm
  },
  data() {
    return {
      pagetitle: "User Details",
      history: [],
      user: {},
      editItemId: null
    };
  },
  created() {
    this.reload();
  },
  props: {
    id:{
       type: String,
       required: true
    }
  },
  computed :{
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('users', {
      loading: 'loading'
    }),
    allowEdit () {
      return (this.currentUser || {}).isAdmin;
    },
  },
  methods: {
    async reload() {
      this.history = await this.loadUserHistory( { id: this.id });
      this.user = await this.loadUser( { id: this.id });
    },
    ...mapActions('users', {
      loadUserHistory: 'loadUserHistory',
      loadUser: 'loadUser'
    }),
    onClose(res) {
      if(res)
        this.reload();
      this.editItemId = null;
    }
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="loading">
    <edit-form v-if="editItemId" @close="onClose" :id="editItemId">
    </edit-form>
    <div class="row">
     <div class="col-lg-6 col-xl-4">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <span>User Information</span>
              <a href="javascript:void(0)" 
                v-if="(currentUser || {}).id !== user.id && !user.isOwner && allowEdit && !user.deletedAt"
                @click="editItemId = user.id">
                <i class="bx bx-pencil font-size-18"></i>
              </a>
            </div>
            
          </div>
          <div class="card-body">
            <dl class="">
              <dt  v-if="user.deletedAt">Deteled At</dt>
              <dd  v-if="user.deletedAt">
                <b class="text-danger">{{$filters.timeAndDate(user.deletedAt)}}</b>
              </dd>

              <dt >Name</dt>
              <dd >{{user.name}}</dd>
              <dt >Email</dt>
              <dd >{{user.email}}</dd>
              <dt >Phone Number</dt>
              <dd >
                <span v-if="user.phoneNumber"> 
                  {{user.phoneNumber}}
                </span>
                <em class="text-muted" v-if="!user.phoneNumber"> 
                  &nbsp;[no data]
                </em>
              </dd>
              <dt >Role</dt>
              <dd >
                <span class="badge bg-dark me-2" v-if="user.isOwner">Owner</span>
                <span class="badge badge-soft-primary mb-0" v-if="user.isAdmin">Admin</span>

                <em class="text-muted" v-if="!user.isOwner && !user.isAdmin"> 
                  &nbsp;[no data]
                </em>
              </dd>
              <dt >Lockout End</dt>
              <dd >
                <span v-if="user.lockoutEnd"> 
                  {{$filters.shortDate(user.lockoutEnd)}}
                </span>
                <em class="text-muted" v-if="!user.lockoutEnd"> 
                  &nbsp;[no data]
                </em>
              </dd>
              <dt >Description</dt>
              <dd >
                <span v-if="user.description"> 
                  {{user.description}}
                </span>
                <em class="text-muted" v-if="!user.description"> 
                  &nbsp;[no data]
                </em>
              </dd>
            </dl>
          </div>
        </div>
     </div>
     <div class="col-lg-6 col-xl-8">
      <div class="card">
        <div class="card-header">User Changes History</div>
        <div class="card-body">
          <span class="text-muted" v-if="history.length === 0">
            No changes found...
          </span>
          <ol class="activity-checkout mb-0 px-4 mt-3" >
            <li class="checkout-item" v-for='historyItem in history' :key="historyItem.id">
              <div class="avatar checkout-icon p-1">
                <div class="avatar-title rounded-circle bg-primary">
                  <i class="bx bxs-pencil text-white font-size-20"></i>
                </div>
              </div>
              <div class="feed-item-list">
                <div>
                  <h5 class="font-size-16 mb-1"><span class="me-2">{{historyItem.insertedBy.name}}</span><small>({{historyItem.insertedBy.email}})</small></h5>
                  <p class="text-muted text-truncate mb-2">
                    {{$filters.timeAgo(historyItem.insertedAt)}}
                  </p>
                  <div class="mb-3">
                     <table class="table table-borderless table-sm mb-0">
                        <thead>
                          <tr>
                            <th><b>Field</b></th>
                            <th>Old Value</th>
                            <th>New Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="historyItem.fields['Name']">
                            <td><b>Name</b></td>
                            <td>
                            <em class="text-muted" v-if="!historyItem.fields['Name'].oldValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['Name'].oldValue.stringValue">
                              {{historyItem.fields['Name'].oldValue.stringValue}}
                            </span>
                            </td>
                            <td>
                              <em class="text-muted" v-if="!historyItem.fields['Name'].newValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['Name'].newValue.stringValue">
                              {{historyItem.fields['Name'].newValue.stringValue}}
                            </span>
                            </td>
                          </tr>
                          <tr v-if="historyItem.fields['Email']">
                            <td><b>Email</b></td>
                            <td>
                            <em class="text-muted" v-if="!historyItem.fields['Email'].oldValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['Email'].oldValue.stringValue">
                              {{historyItem.fields['Email'].oldValue.stringValue}}
                            </span>
                            </td>
                            <td>
                              <em class="text-muted" v-if="!historyItem.fields['Email'].newValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['Email'].newValue.stringValue">
                              {{historyItem.fields['Email'].newValue.stringValue}}
                            </span>
                            </td>
                          </tr>
                          <tr v-if="historyItem.fields['PhoneNumber']">
                            <td><b>Phone Number</b></td>
                            <td>
                            <em class="text-muted" v-if="!historyItem.fields['PhoneNumber'].oldValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['PhoneNumber'].oldValue.stringValue">
                              {{historyItem.fields['PhoneNumber'].oldValue.stringValue}}
                            </span>
                            </td>
                            <td>
                              <em class="text-muted" v-if="!historyItem.fields['PhoneNumber'].newValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['PhoneNumber'].newValue.stringValue">
                              {{historyItem.fields['PhoneNumber'].newValue.stringValue}}
                            </span>
                            </td>
                          </tr>
                          <tr v-if="historyItem.fields['IsAdmin']">
                            <td><b>Is Admin</b></td>
                            <td>
                            <em class="text-muted" v-if="!historyItem.fields['IsAdmin'].oldValue.booleanValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['IsAdmin'].oldValue.booleanValue">
                              yes
                            </span>
                            </td>
                            <td>
                              <em class="text-muted" v-if="!historyItem.fields['IsAdmin'].newValue.booleanValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['IsAdmin'].newValue.booleanValue">
                              yes
                            </span>
                            </td>
                          </tr>
                          <tr v-if="historyItem.fields['LockoutEnd']">
                            <td><b>Lockout End</b></td>
                            <td>
                              <em class="text-muted" v-if="!historyItem.fields['LockoutEnd'].oldValue.dateTimeOffsetValue"> 
                                [no data]
                              </em>
                              <span v-if="historyItem.fields['LockoutEnd'].oldValue.dateTimeOffsetValue">
                                {{$filters.shortDate(historyItem.fields['LockoutEnd'].oldValue.dateTimeOffsetValue)}}
                              </span>
                            </td>
                            <td>
                              <em class="text-muted" v-if="!historyItem.fields['LockoutEnd'].newValue.dateTimeOffsetValue"> 
                              [no data]
                              </em>
                              <span v-if="historyItem.fields['LockoutEnd'].newValue.dateTimeOffsetValue">
                                {{$filters.shortDate(historyItem.fields['LockoutEnd'].newValue.dateTimeOffsetValue)}}
                              </span>
                            </td>
                          </tr>
                          <tr v-if="historyItem.fields['Description']">
                            <td><b>Description</b></td>
                            <td>
                            <em class="text-muted" v-if="!historyItem.fields['Description'].oldValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['Description'].oldValue.stringValue">
                              {{historyItem.fields['Description'].oldValue.stringValue}}
                            </span>
                            </td>
                            <td>
                              <em class="text-muted" v-if="!historyItem.fields['Description'].newValue.stringValue"> 
                              [no data]
                            </em>
                            <span v-if="historyItem.fields['Description'].newValue.stringValue">
                              {{historyItem.fields['Description'].newValue.stringValue}}
                            </span>
                            </td>
                          </tr>
                       </tbody>
                     </table>
                  
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
     </div>
   </div>
   
  </Layout>
</template>
